.clientes-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.clientes-container h1 {
    border-bottom: 2px solid var(--orange);
    margin-bottom: 50px;
    color: white;
    width: max-content;
}

.clientes-container p{
    color: white;
}

.clientes-container .mySwiper{
    width: 100%;
    margin-top: 30px;
}

.clientes-container .mySwiper img{
    width: 50%;
}

.clientes-container .mySwiper .swiper-slide{
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
}

@media (max-width: 420px) {
    .clientes-container .mySwiper img{
        width: 124%;
    }

    .clientes-container .mySwiper .swiper-slide:nth-child(23),
    .clientes-container .mySwiper .swiper-slide:nth-child(22),
    .clientes-container .mySwiper .swiper-slide:nth-child(21),
    .clientes-container .mySwiper .swiper-slide:nth-child(20),
    .clientes-container .mySwiper .swiper-slide:nth-child(19){
        display: none;
    }
}