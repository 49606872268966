.section-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section-container .content-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
}

.section-container .content-title h1{
    margin-bottom: 70px;
    border-bottom: 2px solid var(--orange);
}

.section-container .content-title .top-options{
    display: flex;
    gap: 20px;
    max-width: 900px;
    width: 100%;
}

.section-container .content-title .top-options button {
    width: 50%;
    cursor: pointer;
    background-color: unset;
    border: 0px;
    border-bottom: 1px solid lightgray;
    font-size: 20px;
    padding: 5px;
    transition: .3s;
}

.section-container .content-title .top-options button:hover{
    transition: .3s;
    background-color: #f1f1f1;
}

.opt-selected{
    background-color: #ff6500!important;
    color: white!important;
    transition: .3s!important;
}

.section-container .content-wrapper{
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.section-container .content-wrapper .wrapper-text,
.section-container .content-wrapper .wrapper-img{
    width: 50%;
}

.section-container .content-wrapper .wrapper-text h1{
    margin-bottom: 20px;
}

.section-container .content-wrapper .wrapper-img img {
    width: 80%;
}

@media (max-width: 420px) {
    .section-container .content-wrapper {
        flex-direction: column;
    }

    .section-container .content-wrapper .wrapper-text, .section-container .content-wrapper .wrapper-img {
        width: 100%;
    }

    .videos-container .videos {
        flex-direction: column;
    }

    .videos-container .videos .video-content iframe {
        height: 210px!important;
    }
}