header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    position: fixed;
    top: 0;
    padding: 10px 0px;
    z-index: 999;
    border-bottom: 2px solid var(--orange);
}

header .header-container {
    max-width: 1300px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

header .header-container .header-logo,
header .header-container nav{
    width: 50%;
}

header .header-container .header-logo{
    display: flex;
    align-items: center;
    justify-content: start;
}

header .header-container .header-logo img {
    width: 3rem;
    height: auto;
}


header .header-container nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header .header-container nav ul{
    display: flex;
    gap: 20px;
    list-style: none;
}

header .header-container nav ul li a{
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 300;
}

header .header-container nav ul li a:hover {
    transition: .3s;
    color: var(--orange);
}

.mobile-btn{
    display: none;
}

.show-menu{
    display: flex!important;
    background-color: var(--black);
    position: absolute!important;
    right: 0px;
    top: 0px;
    justify-content: center!important;
    width: 100%!important;
    height: 50vh!important;
}

.show-menu ul{
    display: flex;
    flex-direction: column;
}

.show-menu ul li{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 420px) {
    header .header-container{
        padding: 0 15px;
    }

    header .header-container nav{
        display: none;
    }

    .mobile-btn{
        display: flex;
        background-color: unset;
        justify-content: center;
        align-items: center;
        outline: none;
        border: 0px;
    }

    .mobile-btn svg{
        color: var(--orange);
        width: 40px;
    }

}


