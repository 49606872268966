.localization-container{
    display: flex;
    gap: 50px;
}

.localization-container .wrapper-localization{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.localization-container .wrapper-localization h1 {
    border-bottom: 2px solid var(--orange);
    margin-bottom: 50px;
    color: white;
}

.localization-container .wrapper-localization p{
    max-width: 900px;
    color: white;
}

.localization-container .wrapper-map{
    width: 100%;
}

.localization-container .wrapper-map iframe{
    width: 80%;
    height: 400px;
}

@media (max-width: 420px) {
    .localization-container {
        flex-direction: column-reverse;
    }
}