.contact-container{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: center;
}

.title-contact {
    border-bottom: 2px solid var(--orange);
    margin-bottom: 80px;
    width: max-content;
}

.contact-container .wrapper-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-container .wrapper-info:nth-child(1){
    background-color: var(--black);
    width: 40%;
    margin-right: auto;
    padding: 3%;
    border-radius: 0px 10px 10px 0px;
}

.contact-container .wrapper-info:nth-child(2) {
    margin-right: auto;
}

.contact-container .wrapper-info:nth-child(2) form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-container .wrapper-info:nth-child(2) h3{
    color: black;
    font-size: 30px;
    margin-bottom: 0px;
}

.contact-container .wrapper-info:nth-child(2) form input[type='submit']{
    border: 0px;
    outline: none;
    padding: 5px;
    margin-right: auto;
    width: 30%;
    background-color: var(--orange);
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: .3s;
}

.contact-container .wrapper-info:nth-child(2) form input[type='submit']:hover {
    background-color: #ff9f61;
}

.contact-container .wrapper-info .line-info{
    display: flex;
    gap: 25px;
    align-items: center;
}

.contact-container .wrapper-info h3{
    margin-right: auto;
    color: white;
    margin-bottom: 20px;
}

.contact-container .wrapper-info .social-itens,
.contact-container .wrapper-info .line-info svg {
    width: 40px;
    color: var(--orange);
}

.contact-container .wrapper-info .line-info p a,
.contact-container .wrapper-info .line-info p{
    color: white;
    text-decoration: none;
}

textarea,
input{
    border: 1px solid rgb(209, 209, 209);
    font-size: 1.2rem;
    padding: 1vh;
    outline: none;
}

.not-right{
    border: 1px solid red;
}

.modal-load{
    width: 100%;
    height: 350vh;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.76);
    z-index: 9999999;
}

.modal-load-off{
    display: flex!important;
}

@media (max-width: 420px) {
    #contact{
        padding: 68px 0px!important;
    }

    .contact-container {
        flex-direction: column-reverse;
    }

    .title-contact{
        margin-bottom: 10px!important;
    }

    .contact-container .wrapper-info:nth-child(2) {
        width: 100%;
        padding: 5%;
    }

    .contact-container .wrapper-info:nth-child(1){
        width: 100%;
        padding: 10% 3%;
        border-radius: 0px;
    }

    .contact-container .wrapper-info:nth-child(2) p{
        text-align: left!important;
    }

    .contact-container .wrapper-info .line-info{
        gap: 8px;
    }

    .contact-container .wrapper-info .line-info svg{
        width: 30px!important;
    }

    .contact-container .wrapper-info .line-info p{
        white-space: nowrap;
        font-size: 14px!important;
    }
}