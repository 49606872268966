.who-are-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.who-are-container h1 {
    border-bottom: 2px solid var(--orange);
    margin-bottom: 80px;
    width: max-content;
}

.who-are-container .wrapper-image-who{
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
}

.who-are-container .wrapper-image-who:nth-child(2) {
    margin-bottom: 80px;
}

.who-are-container .wrapper-image-who img {
    width: 50%;
}

@media (max-width: 420px) {
    .who-are-container .wrapper-image-who{
        flex-direction: column;
    }
    .who-are-container .wrapper-image-who img{
        width: 100%;
    }

    .who-are-container .wrapper-image-who:nth-child(3){
        flex-direction: column-reverse;
    }
}