.videos-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.videos-container .videos-title h1{
    color: #fff;
    margin-bottom: 70px;
    width: max-content;
    border-bottom: 2px solid var(--orange);
}

.videos-container .videos{
    display: flex;
    gap: 30px;
    width: 100%;
}

.videos-container .videos .video-content{
    width: 100%;
}

.videos-container .videos .video-content h3 {
    color: white;
    font-weight: 300;
}

.videos-container .videos .video-content iframe{
    width: 100%;
    height: 450px;
}