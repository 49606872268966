footer{
    background-color: var(--black);
    padding: 30px 130px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

footer .footer-container{
    display: flex;
    gap: 50px;
}

footer .footer-container .menu-footer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
}

footer .footer-container .menu-footer h1 {
    color: var(--orange);
    font-size: 26px;
    margin-bottom: 20px;
}

footer .footer-container .menu-footer a{
    text-decoration: none;
    color: #fff;
}

footer .footer-container .menu-footer svg{
    width: 35px;
}

footer .footer-container .menu-footer:nth-child(3) img{
    width: 40%;
    margin-left: auto;
}

footer p:nth-child(2){
    margin-bottom: 10px;
    margin-top: 50px;
}

footer p:nth-child(2),
footer p:nth-child(3){
    color: white;
    font-size: 14px!important;
}

@media (max-width: 420px) {
    footer{
        padding: 30px;
    }

    footer .footer-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer .footer-container .menu-footer:nth-child(3) img {
        margin-left: unset;
    }

    footer .footer-container .menu-footer {
        align-items: center;
    }
}