.hats-container{
    width: 100%;
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hats-container h1{
    border-bottom: 2px solid var(--orange);
    width: max-content;
}

.hats-container .wrapper{
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: center;
}

.hats-container .wrapper-hats img {
    width: 50%;
}

.hats-container .wrapper-hats h3{
    font-size: 22px;
}

.hats-container .wrapper-hats p a{
    color: var(--orange);
}

@media (max-width: 420px) {
    .hats-container .wrapper {
        flex-direction: column;
    }

    .hats-container .wrapper-hats img {
        width: 100%;
    }
}
