:root{
  --orange: #ff6500;
  --black: #2b2b2b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

section{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px;
}

h1{
  font-size: 35px;
}

p{
  font-size: 20px!important;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 420px) {
  h1{
    font-size: 25px;
  }

  section{
    padding: 100px 30px;
  }

  p{
    font-size: 16px!important;
  }
}