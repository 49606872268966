.bg-black{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    height: 100%;
    display: flex;
    justify-content: center;
}

.bg-black .bg-title {
    margin-top: 5%;
    text-align: left;
    max-width: 1300px;
    width: 100%;
}

.bg-black .bg-title h1 {
    font-size: 60px;
    margin: 0;
    line-height: 65px;
    color: white;
}

.bg-black .bg-title h1:nth-child(2) {
    color: var(--orange);
    font-size: 80px;
}

.bg-black .bg-title p{
    margin-top: 20px;
    color: #fff;
    font-size: 22px;
    max-width: 900px;
}

.bg-black .bg-title p a {
    text-decoration: none;
    color: var(--orange);
}

.bg-black .bg-title p a:hover {
    transition: .3s;
    color: #FFA46DFF;
}

.bg-black .bg-title .wpp-action{
    background-color: var(--orange);
    text-decoration: none;
    color: white;
    border-radius: 20px;
    padding: 8px;
    transition: .3s;
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;
    margin-top: 15px;
    cursor: pointer;
}

.bg-black .bg-title .wpp-action:hover{
    transition: .3s;
    background-color: #ff9f61;
}

.roll-down{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    width: 30px;
    height: 50px;
    border-radius: 10px;
    background-color: #ff6500;
    cursor: pointer;
}

.roll-down svg {
    width: 30px;
    color: #fff;
    animation: go-back linear infinite 1s;
}

@keyframes go-back {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 420px){
    .bg-black .bg-title h1 {
        font-size: 30px;
        line-height: 45px;
    }

    .bg-black .bg-title h1:nth-child(2) {
        font-size: 40px;
    }

    .bg-black .bg-title .btn-actions{
        flex-direction: column;
    }

    .bg-black .bg-title .btn-actions a{
        border-radius: 10px;
        width: 70%;
        padding: 5px;
    }

    .bg-black .bg-title {
        margin-top: 5%;
        margin-left: 5%;
    }
}